import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage"; 
import { storage } from "./firebaseConfig"; 

export const uploadImageToFirebase = async (file) => {
  const storageRef = ref(storage, `images/${file.name}`);
  
  try {
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading image to Firebase:", error);
    throw error;
  }
};

export const getImageUrlFromFirebase = async (imageId) => {
  const storageRef = ref(storage, `images/${imageId}`);
  
  try {
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Error getting image URL from Firebase:", error);
    throw error;
  }
};

export const getAllImagesFromFirebase = async () => {
  const listRef = ref(storage, 'images');
  let imageUrls = [];

  try {
    const res = await listAll(listRef);
    for (const itemRef of res.items) {
      const downloadURL = await getDownloadURL(itemRef);
      imageUrls.push({
        id: itemRef.name,
        name: itemRef.name,
        url: downloadURL
      });
    }
    return imageUrls;
  } catch (error) {
    console.error("Error fetching images from Firebase:", error);
    throw error;
  }
};
