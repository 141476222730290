import React from 'react';
import { Button } from '@mui/material';
import { uploadImageToFirebase } from '../apiHandlers';

const ImageUpload = ({ onImageUpload }) => {
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const url = await uploadImageToFirebase(file);
                onImageUpload(url);
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    return (
        <div>
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
                <Button
                    variant="contained"
                    component="span"
                    sx={{
                        background: '#ffd700',
                        '&:hover': { background: '#e6c200' },
                        color: '#0057b7'
                    }}
                >
                    Upload Image
                </Button>
            </label>
        </div>
    );
};

export default ImageUpload;
